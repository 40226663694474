<template>
    <div class="card col-md-8 col-lg-6 col-xl-5 col-xl2-4 mx-auto px-0">
        <div class="card-body">
            <h4 class="my-5 lead font-weight-medium text-center">Reset your password</h4>
            <form id="reset-password-form" class="mb-5" @submit.prevent="handleSubmit">
                <VueRecaptcha :sitekey="sitekey"
                              size="invisible"
                              :loadRecaptchaScript="true"
                              @verify="resetPassword"
                              @expired="resetRecaptcha"/>
                <Input class="mb-4"
                       class-name="form-control-lg"
                       name="email"
                       type="email"
                       label="Email address"
                       placeholder="Enter your email"
                       autocomplete="username"
                       @change="handleChange"
                       :value="email"
                       :v="$vServer.email || $v.email"/>
                <div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
                    <button type="submit" class="btn btn-primary btn-block btn-lg py-2 px-5">Reset</button>
                </div>
            </form>
            <hr class="my-5">
            <p class="text-center mt-2">
                <router-link :to="{name: 'login'}">Back to the login page</router-link>
            </p>
        </div>
    </div>

</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {sitekey} from "@/config/recaptcha";
import {scrollToError} from "@/helpers";
import Input from "@/components/form/Input";
import VueRecaptcha from "vue-recaptcha";
import {FORGOT_PASSWORD_URL} from "@/config/api";

export default {
    name: "ForgotPasswordForm",
    data: () => ({
        submitted: false,
        email: null,
    }),
    components: {Input, VueRecaptcha},
    validations: {
        email: {required, email},
    },
    computed: {
        ...mapGetters({
            '$vServer': 'serverNotice/errors'
        }),
        sitekey() {
            return sitekey;
        },
        recaptcha() {
            return this.$children[0];
        }
    },
    methods: {
        handleChange({name, value}) {
            if (this[name] !== value) {
                // clear server errors
                this.$store.dispatch('serverNotice/clearErrors');
                this[name] = value;
            }
        },
        async handleSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$nextTick(() => scrollToError(this.$el))
            } else {
                this.recaptcha.execute(); // run recaptcha
            }
        },
        async resetPassword() {
            if (this.submitted) return false;

            this.submitted = true;
            this.$store.dispatch('serverNotice/clearNotice');

            try {
                this.$root.$preloader.start();

                const {email} = this;
                const {data} = await this.$http.post(FORGOT_PASSWORD_URL, {email}, {
                    timeout: 10000,
                });

                this.$store.dispatch('serverNotice/setNotice', data);
            } catch (e) {
                const {data, status} = e.response;
                if (!data.status)
                    data.status = status;
                this.$store.dispatch('serverNotice/setNotice', data);
            }

            this.resetForm();
            this.resetRecaptcha();
            this.$root.$preloader.finish();
        },
        resetForm() {
            this.email = null;
            this.submitted = false;
            this.$v.$reset();
        },
        resetRecaptcha() {
            this.recaptcha.reset();
        },
    }
}
</script>
<style lang="scss">
#reset-password-form {
    .form-control-lg {
        @include font-size($input-font-size);
        height: 50px;
    }
}
</style>

